import React, { useContext, useState } from "react";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import MenuItem from "@mui/material/MenuItem";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";
import { tokens } from "../../theme";
import ListItemIcon from "@mui/material/ListItemIcon";
import { AddCircle, EnergySavingsLeaf, LocalShipping } from "@mui/icons-material";
import SpeedIcon from "@mui/icons-material/Speed";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";
import PersonIcon from "@mui/icons-material/Person";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import api from "../../axios";
import MainContext from "../../contexts/MainContext";
import { toast } from "react-toastify";
import AddAssetModal from "../Modals/AddAssetModal";
import EditAsset from "./EditAssetModal";
import AddTotalizerModal from "./AddTotalizerModal";

export default function BasicCard({
  assetId,
  assetType,
  assetName,
  capacity,
  location_name,
  pincode,
  location_id,
  state,
  incharge_name,
  incharge_id,
  last_order,
  reg_no,
  fuelType,
  is_totalizer_updated,
  last_totalizer_updated
}) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = window.innerWidth <= 600;

  const { getAssetData } = useContext(MainContext);

  let assetIcon = null;

  if (assetType === "Datum") {
    assetIcon = require("../../assets/datum.png");
  } else if (assetType === "Heavy vehicle") {
    assetIcon = require("../../assets/truck.png");
  } else if (assetType === "Infra Machine") {
    assetIcon = require("../../assets/citytech-logo.png");
  } else if (assetType === "DG set") {
    assetIcon = require("../../assets/generator.png");
  } else if (assetType === "Industrial") {
    assetIcon = require("../../assets/factory.png");
  } else if (assetType === "Others") {
    assetIcon = require("../../assets/oil-pump.png");
  } else {
    assetIcon = require("../../assets/industry.png");
  }

  // const asset = {
  //   All:require('../../assets/industry.png'),
  //   Datum: require('../../assets/datum.png'),
  //   HeavyVehicle:require('../../assets/truck.png'),
  //   InfraMachine:require('../../assets/citytech-logo.png'),
  //   DgSet:require('../../assets/generator.png'),
  //   Industrial:require('../../assets/factory.png'),
  //   Others: require('../../assets/oil-pump.png'),
  // }

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleDelete = () => {
    api
      .delete(`assets/${assetId}`)
      .then((response) => {
        getAssetData();
        handleClose();
      })
      .catch((error) => {
        toast.error(error, "something went wrong");
        if (error.response.status === 403){
          window.location.reload()
        }
      });
  };

  return (
    <Card
      sx={{
        minWidth: 275,
        bgcolor: colors.card2["bg"],
        position: "relative",
        // transition: "background-color 0.3s ease",
        // transition: "transform 0.3s ease",
        transition: "box-shadow 0.3s ease, transform 0.3s ease",
        cursor: "pointer",
        "&:hover": {
          // backgroundColor: colors.grey[900],
          boxShadow: "0 0 8px rgba(0, 0, 0, 0.3)",
          transform: "scale(1.05)",
        },
      }}
    >
      <CardContent>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <ListItemIcon sx={{ minWidth: "auto" }}>
            {/* <LocalShipping  sx={{ fontSize: "2.5rem",color:colors.grey[200] }} /> */}
            <img
              style={{ filter: colors.pngcol["filter"] }}
              height="30"
              width="30"
              src={assetIcon}
            />
          </ListItemIcon>
          <div>
            <Typography variant="h5" component="div">
              {assetName}&nbsp;
              {reg_no && (
                <span
                  style={{ border: "1px solid darkgrey", paddingInline: "2px" }}
                >
                  {reg_no}
                </span>
              )}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {assetType}
            </Typography>
          </div>
        </Box>

        <>
          <IconButton
            onClick={handleClick}
            sx={{
              position: "absolute",
              top: "10px",
              right: "8px",
            }}
          >
            <MoreVertIcon />
          </IconButton>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            {/* <MenuItem onClick={handleClose}>Edit</MenuItem> */}
            <EditAsset
            updateData={getAssetData}
              info={{
                assetId,
                assetType,
                assetName,
                capacity,
                pincode,
                location_id,
                state,
                incharge_id,
                last_order,
                reg_no,
                fuelType,
              }}
            />
            <MenuItem onClick={handleDelete}>Delete</MenuItem>
          </Popover>
        </>

<Box display='flex' flexDirection='column'>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // height: 80,
            paddingY:1,
            //   backgroundColor: colors.grey[800],
            borderRadius: 4,
            // marginTop: 2,
            gap: 4,
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <PersonIcon sx={{ fontSize: "2rem", color: colors.grey[500] }} />
            <Box>
              <Typography variant="h4" sx={{ textAlign: "center" }}>
                {incharge_name}
              </Typography>
              <Typography fontSize="0.9rem" variant="h6" color="text.secondary">
                Incharge
              </Typography>
            </Box>
          </Box>
          {/* <Box>
            <Typography sx={{textAlign:'center'}}>0Ltr/Wk</Typography>
            <Typography sx={{fontSize:isMobile?'12px':'15px'}} variant="p" color="text.secondary">
              Last Refill
            </Typography>
            </Box> */}
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <LocalGasStationIcon
              sx={{ fontSize: "2rem", color: colors.grey[500] }}
            />
            <Box>
              <Typography variant="h6" sx={{ textAlign: "center" }}>
                {last_order ? last_order : "No Data"}
              </Typography>
              <Typography fontSize="0.9rem" variant="h6" color="text.secondary">
                Last Order
              </Typography>
            </Box>
          </Box>

          
          </Box>
          
         
          
        </Box>
        <Typography sx={{ fontSize: "1rem" }} color="text.secondary">
          <span>Location </span> <br></br>
          <span style={{ fontWeight: "600", color: colors.pure[900] }}>
            {location_name}
          </span>
        </Typography>
        <span
          style={{
            fontSize: "14px",
            // color: colors.grey[500],
            position: "absolute",
            bottom: "18px",
            right: "28px",
          }}
        >
          <Typography color="text.secondary">
            Capacity <br></br>
          </Typography>
          <Typography
            style={{ display: "flex", alignItems: "center", fontWeight: "700" }}
          >
            {/* <SpeedIcon style={{ marginRight: "4px" }} /> */}
            {capacity} Ltrs
          </Typography>
        </span>
        {/* <Typography 
        color="text.primary" 
        fontWeight='700' 
        fontSize='0.8rem'
        >{state.charAt(0).toUpperCase() + state.slice(1).toLowerCase()}</Typography> */}
      </CardContent>
    </Card>
  );
}
