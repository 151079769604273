import { Box, Typography, useTheme, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../theme";
import { mockDataTeam } from "../data/mockData";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../components/Header";
import AddCompanyModal from "../components/Modals/AddCompanyModal";
import BasicTable from "../components/Tables/BasicTable";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { getRoles } from "@testing-library/react";
import api from "../axios";
import Carousel from "../components/SlickSlider";
import AddAssetModal from "../components/Modals/AddAssetModal";
import MainContext from "../contexts/MainContext";
import AssetsBox from "../components/AssetPage/AssetsBox";
import { useMediaQuery } from '@mui/material'
import { AuthContext } from "../AuthContext";


const AssetPage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { setAssetFilter, assetData, getAssetData } = useContext(MainContext);
  console.log('assetDaaaaa',assetData);
  const isMobile = useMediaQuery('(max-width : 600px)')

  // const getData = () => {
  //   api.get(`assets/${token.id}`).then((response) => {
  //     console.log(response.data);
  //     setData(response.data);
  //   });
  // }

  
  // const [data, setData] = useState([]);

  // useEffect(() => {
  //   getData();
  // }, []);

  return (
    <Box sx={{ marginInline: "20px" }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Your Assets" subtitle="Manage your assets here" />
        <Box>
          <AddAssetModal type='add' updateData={getAssetData}></AddAssetModal>
        </Box>
      </Box>

      <Box maxWidth="70vw">
        <Carousel />
      </Box>


      <Box
        borderRadius='10px'
        height={isMobile ? "500px" :'390px'}
        marginTop="20px"
        overflow='auto'
      >
        <AssetsBox data={assetData}/>
      </Box>
    </Box>
  );
};

export default AssetPage;
