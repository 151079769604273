import React, { useContext, useState, useEffect } from "react";
import MainContext from "../../contexts/MainContext";
import api from "../../axios";
import BasicCard from "./StaffCard";
import axios from "axios";
import { AuthContext } from "../../AuthContext";
import { useMediaQuery,useTheme } from "@mui/material";
import { tokens } from "../../theme";

function StaffBox({data}) {
  const { assetFilter } = useContext(MainContext);
  const { token } = useContext(AuthContext);
  //   const [selected, setSelected] = useState(assetFilter);

 

  const isMobile = useMediaQuery('(max-width: 600px)');
  
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);



  const dataq = [
    {
      typeOfAsset: "Heavy vehichle",
      assetName: "Asset Name",
      capacity: "500 ltrs",
      location: "Location Name",
      state: "State Name",
      fuelType: "Diesel",
    },
    {
      typeOfAsset: "Datum",
      assetName: "Asset Name",
      capacity: "500 ltrs",
      location: "Location Name",
      state: "State Name",
      fuelType: "Diesel",
    },
    {
      typeOfAsset: "Heavy vehicle",
      assetName: "Asset Name",
      capacity: "500 ltrs",
      location: "Location Name",
      state: "State Name",
      fuelType: "Diesel",
    },
  ];

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        gap: 20,
        flexDirection: "row",
        padding: 8,
        justifyContent: "center",
        alignItems: "center",
        paddingBottom:isMobile?150:50
      }}
    >
      {data.map((obj) => {
        console.log('kkkkkkkkkkkkkkk',obj);
        return (            
              <div style={{ flexBasis: "50%", maxWidth: 320 }}>
                <BasicCard
                  staffName={obj.username}
                  staffNumber={obj.number}
                  assetInfo={obj.assets_incharge}
                  assetType={obj.assets_incharge?.typeOfAsset}
                  sx={{ width: "500px"}} />
              </div>   )  
      })}
    </div>
  );
}

export default StaffBox;
