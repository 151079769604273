import React, { createContext, useContext, useEffect, useState } from 'react';
import { AuthContext } from '../AuthContext';
import api from '../axios';

// Create the context
const MainContext = createContext();

// Create a provider component to wrap the components that need access to the selected slide state
export const MainContextProvider = ({ children }) => {
  const { token } = useContext(AuthContext)

  const [assetData, setAssetData ] = useState([])


  const getAssetData = () => {
    let companyid;

    if (token ){
      companyid = token.company_id?token.company_id:token.id
      api.get(`assets/${companyid}`).then((response) => {
        console.log(',.,.,,.,.,.,.',response.data);
        setAssetData(response.data);
      }).catch((error)=>{
        if (error.response.status === 403){
          window.location.reload()
        }
      });
    }
   
  }

  useEffect(() => {
    console.log('token use effect triggered');
    // if ()
    getAssetData()
  }, [token])
  

  const [assetFilter, setAssetFilter] = useState('All');

  return (
    <MainContext.Provider value={{assetFilter, setAssetFilter, assetData, setAssetData, getAssetData}}>
      {children}
    </MainContext.Provider>
  );
};

export default MainContext;
