import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import ProgressCircle from "./ProgressCircle";
import { useMediaQuery } from "@mui/material";

const StatBox = ({ title, subtitle, icon, progress, increase }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const isMobile = useMediaQuery("(max-width : 600px)");
  return (
    <Box width="100%" m="0 5px" >
      <Box display="flex" justifyContent="center" alignItems="center">
        <Box
   
        display="flex" justifyContent="center" alignItems="center" flexDirection="column">
          {icon}
          <Typography
            // variant="h4"
            fontWeight="bold"
            sx={{ color: colors.grey[100],fontSize:isMobile?'0.9rem':'1.2rem' }}
          >
            {title}
          </Typography>
        </Box>
        {/* <Box>
          <ProgressCircle progress={progress} />
        </Box> */}
      </Box>
      <Typography 
      // variant="h6"
      
       textAlign="center" sx={{ color: colors.greenAccent[200], fontWeight:'600',fontSize:isMobile?'0.7rem':'1rem' }}>
          {subtitle}
        </Typography>
      {/* <Box display="flex" justifyContent="space-between" mt="2px"> */}
        
        {/* <Typography
          variant="h5"
          fontStyle="italic"
          sx={{ color: colors.greenAccent[200] }}
        >
          {increase}
        </Typography> */}
      {/* </Box> */}
    </Box>
  );
};

export default StatBox;
