import { Box, Typography, useTheme, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../theme";
import { mockDataTeam } from "../data/mockData";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../components/Header";
import AddCompanyModal from "../components/Modals/AddCompanyModal";
import BasicTable from "../components/Tables/BasicTable";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { getRoles } from "@testing-library/react";
import api from "../axios";
import Carousel from "../components/SlickSlider";
import AddAssetModal from "../components/StaffPage/AddStaffModal";
import MainContext from "../contexts/MainContext";
import OrderBox from "../components/OrderPage/OrderBox";
import { useMediaQuery } from "@mui/material";
import { AuthContext } from "../AuthContext";
import HistoryBox from "../components/OrderHistoryPage/HistoryBox";
import HistoryBoxTable from "../components/OrderHistoryPage/HistoryBoxTable";
import InvoiceTable from "../components/InvoicePage/InvoiceTable";

const InvoicePage = ({ order_type }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { token } = useContext(AuthContext);
  const [rowData, setRowData] = useState([]);
  const { setAssetFilter } = useContext(MainContext);
  const isMobile = useMediaQuery("(max-width : 600px)");

  //   const getData = () => {
  //     api.get(`staff/${token.id}`).then((response) => {
  //       console.log('yyyyyyyyyyyyyyyyyyyyy',response.data);
  //       setData(response.data);
  //     });
  //   }

  //   const [data, setData] = useState([]);

  //   useEffect(() => {
  //     getData();
  //   }, []);

  return (
    <Box sx={{ marginInline: "20px" }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header
          title='Your Invoices'
        //   subtitle={
        //     order_type === "client"
        //       ? "Your Fuel Stories"
        //       : "Romulus Fuel Stories"
        //   }
        />
        {/* <Box>
          <AddAssetModal updateData={getData}></AddAssetModal>
        </Box> */}
      </Box>

      {/* <Box maxWidth="70vw">
        <Carousel />
      </Box> */}

      <Box
        borderRadius="10px"
        height={isMobile ? "500px" : "70vh"}
        marginTop="10px"
        // overflow='auto'
      >
        {/* <OrderBox data={data}/> */}
        {/* {isMobile ? (
          <HistoryBox order_type={order_type} />
        ) : (
          <HistoryBoxTable order_type={order_type} />
        )} */}

        <InvoiceTable fetch_type='single'/>
      </Box>
    </Box>
  );
};

export default InvoicePage;
