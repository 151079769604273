import React, { useContext, useState, useEffect } from "react";
import MainContext from "../../contexts/MainContext";
import api from "../../axios";
// import BasicCard from "./Card";
import { AuthContext } from "../../AuthContext";
import {
  Box,
  useMediaQuery,
  useTheme,
  FormControl,
  Typography,
  Button,
  Grid,
} from "@mui/material";
import { tokens } from "../../theme";
import { toast } from "react-toastify";
import { LocalGasStation } from "@mui/icons-material";

function OrderBox({ data }) {
  const { token } = useContext(AuthContext);
  //   const [selected, setSelected] = useState(assetFilter);

  const company_id = token.company_id?token.company_id:token.id
  const isMobile = useMediaQuery("(max-width: 600px)");

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [quantity, setQuantity] = useState('');

  const [total, setTotal] = useState(0);
  const handleQty = (qty) => {
    setError('')
    setQuantity(qty);
    setTotal((parseFloat(dieselPrice) * qty) - (parseFloat(discountPrice) * qty)); 
  };


  const [dieselPrice, setDieselPrice] = useState(85)
  const [discountPrice, setDiscountPrice] = useState(1)
  const [error,setError] = useState('')

  const [locationData,setLocationData] = useState([])
  const getDieselPrice = async () =>{
    await api.get(`get-diesel-price/${company_id}`).then((response)=>{
      console.log('rrrrrrrrrr',response.data);
      setDieselPrice(response.data.diesel_price)
      setDiscountPrice(response.data.discount?response.data.discount:0)
      setLocationData(response.data.locations)
    })
  }


  useEffect(() => {
    getDieselPrice()
  
  }, [])
  
  const [formData, setFormData] = useState({
    // asset: "",
    requested_quantity: "",
    company: "",
    diesel_price: "",
    total_price: "",
    ordered_by: "",
    ordered_user_type: "",
    order_status: "Ordered",
  });

  const handleChange = (event) => {
    console.log(formData);
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = () => {
    
    if (location === ''){
      setError('Select One Location')
      // return
    }else if (quantity === "") {
      setError("Enter the Quantity");
      // return
    }else {

      
      setIsLoading(true);
    const details = {
      // asset: asset,
      requested_quantity: quantity,
      company: token.company_id ? token.company_id : token.id,
      ordered_by: token.id,
      ordered_user_type: token.user_type,
      diesel_price: dieselPrice,
      discount_price:discountPrice,
      requested_total_price: total,
      order_status: "Ordered",
      order_type: "romulus",
      location
      
    };
    console.log(details);
    api
    .post("order", details)
    .then((response) => {
      toast.success("Order Placed Successfully");
      // setIsLoading(false);
    })
    .catch((error) => {
      toast.error(error.response.data.message);
      console.log('====================================');
        console.log(error.response);
        console.log('====================================');
        
        // setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false); 
      });
      
    }
      ;

      // setIsLoading(false)
  };



    const [location, setLocation] = useState("");

    const handleLocationChange = (event) => {
      setError('')
      setLocation(event.target.value);
    };

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        gap: 20,
        flexDirection: "row",
        padding: 8,
        justifyContent: "center",
        alignItems: "center",
        // paddingBottom: isMobile ? 50 : 50,
      }}
    >
      {/* {data.map((obj) => {
        console.log('kkkkkkkkkkkkkkk',obj);
        return (            
              <div style={{ flexBasis: "50%", maxWidth: 320 }}>
                <BasicCard
                  staffName={obj.username}
                  staffNumber={obj.number}
                  assetInfo={obj.assets_incharge}
                  assetType={obj.assets_incharge?.typeOfAsset}
                  sx={{ width: "500px"}} />
              </div>   )  
      })} */}

      <Box
        display="flex"
        backgroundColor={colors.card2["bg"]}
        width="100%"
        // paddingX="10%"
        paddingY="2%"
        borderRadius="10px"
        // gap="40px"
        justifyContent="center"
        alignItems="center"
        boxShadow="0 0 8px rgba(0, 0, 0, 0.3)"
        flexDirection={isMobile ? "column" : "column"}
      >
        <Box width={isMobile ? "100%" : "60%"}>
          <div className="relative mb-1">
            <select
              value={location}
              onChange={handleLocationChange}
              className="block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-indigo-500 focus:shadow-outline"
            >
              <option value="">Select a location</option>

              {locationData.map((obj) => (
                <option value={obj.id}>{obj.location}</option>
              ))}

              {/* Add more options as needed */}
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              {/* Custom location icon */}
              <svg
                className="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M10 0C4.477 0 0 4.477 0 10c0 3.42 1.998 6.323 4.876 7.691.213.078.457-.018.534-.231.075-.21-.019-.455-.231-.534C2.916 15.444 2 12.862 2 10 2 5.364 5.364 2 10 2s8 3.364 8 8c0 2.862-.916 5.444-2.179 7.926-.212.52.251 1.047.788.874C18.546 16.323 20 13.42 20 10 20 4.477 15.523 0 10 0zm0 18c-3.314 0-6-2.686-6-6 0-3.314 2.686-6 6-6 3.314 0 6 2.686 6 6 0 3.314-2.686 6-6 6z"></path>
              </svg>
            </div>
          </div>

          {/* <FormControl fullWidth className="bg-white"> */}
            <input
              required
              style={{ backgroundColor: colors.card["bg"] }}
              name="quantity"
              value={quantity}
              placeholder="Quantity in Liters"
              onChange={(e) => {
                handleQty(e.target.value);
              }}
              className="w-full bg-white px-4 py-2 rounded-sm border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />

            <Box
              sx={{
                display: "flex",
                marginTop: "8px",
                gap: 1,
                overflowX: "auto",
                paddingTop: "5px",
                "&::-webkit-scrollbar": {
                  width: "10px",
                },
              }}
            >
              <Button
                variant="contained"
                sx={{
                  bgcolor: colors.grey[900],
                  color: colors.pure[900],
                  "&:hover": { bgcolor: colors.grey[800] },
                }}
                onClick={() => {
                  handleQty(500);
                }}
              >
                500
              </Button>
              <Button
                variant="contained"
                sx={{
                  bgcolor: colors.grey[900],
                  color: colors.pure[900],
                  "&:hover": { bgcolor: colors.grey[800] },
                }}
                onClick={() => {
                  handleQty("1000");
                }}
              >
                1000
              </Button>
              <Button
                variant="contained"
                sx={{
                  bgcolor: colors.grey[900],
                  color: colors.pure[900],
                  "&:hover": { bgcolor: colors.grey[800] },
                }}
                onClick={() => {
                  handleQty("2000");
                }}
              >
                2000
              </Button>
              <Button
                variant="contained"
                sx={{
                  bgcolor: colors.grey[900],
                  color: colors.pure[900],
                  "&:hover": { bgcolor: colors.grey[800] },
                }}
                onClick={() => {
                  handleQty("3000");
                }}
              >
                3000
              </Button>
              <Button
                variant="contained"
                sx={{
                  bgcolor: colors.grey[900],
                  color: colors.pure[900],
                  "&:hover": { bgcolor: colors.grey[800] },
                }}
                onClick={() => {
                  handleQty("5000");
                }}
              >
                5000
              </Button>

              <Button
                variant="contained"
                sx={{
                  bgcolor: colors.grey[900],
                  color: colors.pure[900],
                  "&:hover": { bgcolor: colors.grey[800] },
                }}
                onClick={() => {
                  handleQty("6000");
                }}
              >
                6000
              </Button>

              <Button
                variant="contained"
                sx={{
                  bgcolor: colors.grey[900],
                  color: colors.pure[900],
                  "&:hover": { bgcolor: colors.grey[800] },
                }}
                onClick={() => {
                  handleQty("");
                }}
              >
                Custom
              </Button>
            </Box>
          {/* </FormControl> */}
        </Box>

        <Box
          width="100%"
          display="flex"
          gap="5px"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
        >
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="row"
            alignItems="center"
          >
            <Box
              display="flex"
              justifyContent="center"
              flexDirection="row"
              alignItems="center"
              sx={{
                border: "solid 1px darkgrey",
                p: 1,
                borderRadius: 1,
                mb: 1,
                mt: 2,
                width: !isMobile ? "450px" : "auto",
              }}
            >
              <LocalGasStation sx={{ fontSize: "45px" }} />
              <Grid container alignItems="center" spacing={1}>
                <Grid item sm={6}>
                  <Typography fontSize="15px">Diesel Price:</Typography>
                  <Typography fontSize="15px">Discount Price:</Typography>
                  <Typography fontSize="15px">Sub Total:</Typography>
                  <Typography fontSize="15px">Total Discount:</Typography>
                  <Typography fontSize="15px" fontWeight="700">
                    Total Amount:
                  </Typography>
                </Grid>
                <Grid item sm={6}>
                  <Typography fontSize="15px" textAlign="right">
                    {`Rs. ${dieselPrice}/Ltr`}
                  </Typography>
                  <Typography fontSize="15px" textAlign="right">
                    {`Rs. ${discountPrice}/Ltr`}
                  </Typography>
                  <Typography fontSize="15px" textAlign="right">
                    {`Rs. ${quantity * dieselPrice}`}
                  </Typography>
                  <Typography fontSize="15px" textAlign="right">
                    {`- Rs. ${quantity * discountPrice}`}
                  </Typography>
                  <Typography
                    fontSize="15px"
                    fontWeight="700"
                    textAlign="right"
                  >
                    <span
                      style={{
                        backgroundColor: "blue",
                        borderRadius: "5px",
                        paddingInline: "4px",
                        color: "white",
                      }}
                    >{`Rs. ${total}`}</span>
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>

          {/* <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
          >
            <LocalGasStationIcon sx={{ fontSize: "45px" }} />

            <Typography fontSize="15px" fontWeight="700">
              Diesel Price : {`Rs. ${dieselPrice}/Ltr`}
            </Typography>

            <Typography fontSize="15px" fontWeight="700">
              Discount Price : {`Rs. ${discountPrice}/Ltr`}
            </Typography>

            <Typography fontSize="15px" fontWeight="700">
              Total Price :{" "}
              <span
                style={{
                  backgroundColor: "blue",
                  borderRadius: "5px",
                  paddingInline: "4px",
                  color: "white",
                }}
              >{`Rs. ${total}`}</span>
            </Typography>
          </Box> */}

          {error &&<span
          className="text-red-600 font-bold"
          >
            {error}...!
          </span>}

          {!isLoading ? (
            <Button onClick={handleSubmit} size="large" variant="contained">
              Order Now
            </Button>
          ) : (
            <Button disabled size="large" variant="contained">
              Ordering Please Wait
            </Button>
          )}
        </Box>
      </Box>
    </div>
  );
}

export default OrderBox;
