import { Box, Typography, useTheme, Button, Divider, CircularProgress } from "@mui/material";

import { tokens } from "../theme";

import Header from "../components/Header";

import { useContext, useEffect, useState } from "react";

import api from "../axios";
import MainContext from "../contexts/MainContext";

import { useMediaQuery } from "@mui/material";
import StatBox from "../components/StatBox";
import {
  LocalGasStation,
  Route,
  Co2,
  CurrencyRupee,
  Money,
  Payment,
  Sell,
  Info,
} from "@mui/icons-material";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { AuthContext } from "../AuthContext";

// import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import "react-lazy-load-image-component/src/effects/opacity.css";
import CountUp from "react-countup";
import { LazyLoadImage } from "react-lazy-load-image-component";

const AssetPage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [rowData, setRowData] = useState();
  const { setAssetFilter } = useContext(MainContext);
  const isMobile = useMediaQuery("(max-width : 600px)");
  const { token } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true)

  const getData = () => {

    api
      .get(`dashboard_data/${token.company_id ? token.company_id : token.id}`)
      .then((response) => {
        setRowData(response.data);
      })
      .catch((error) => {
        if (error.response.status === 403){
          window.location.reload()
        }
      });
      setIsLoading(false)
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Box sx={{ marginInline: "20px" }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Dashboard" subtitle="Overview Of this Month" />
        {/* <Box>
          <AddAssetModal ></AddAssetModal>
        </Box> */}
      </Box>

      {/* <Box maxWidth="70vw"></Box> */}

      <Box
        borderRadius="10px"
        height={isMobile ? "500px" : "490px"}
        maxWidth={isMobile ? "auto" : "78vw"}
        marginTop="10px"
        // overflow="hidden"
      >
        {/* <AssetsBox /> */}
        {/* <Typography variant="h3" color={colors.grey[500]} fontWeight="600">Overview Of this Month</Typography> */}
        <Box display="flex" marginTop="5px" gap="10px">
          <Box
            height="120px"
            width={"100%"}
            backgroundColor={colors.card2["bg"]}
            borderRadius="4px"
            boxShadow="0 2px 6px rgba(0, 0, 0, 0.16)"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              // sx={{fontSize:'1rem'}}
              title={`${
                rowData?.monthly_purchase_quantity === undefined ||
                rowData?.monthly_purchase_quantity === null
                  ? "0"
                  : rowData?.monthly_purchase_quantity
              } Ltrs`}
              subtitle="Monthly Purchase Quantity"
              //   progress="0.75"
              //   increase="+14%"
              icon={
                <LocalGasStation
                  sx={{ color: colors.pure[900], fontSize: "36px" }}
                />
              }
            />
          </Box>

          <Box
            height="120px"
            width={"100%"}
            backgroundColor={colors.card2["bg"]}
            borderRadius="4px"
            boxShadow="0 2px 6px rgba(0, 0, 0, 0.16)"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title={`₹ ${
                rowData?.monthly_purchase_cost === undefined ||
                rowData?.monthly_purchase_cost === null
                  ? "0"
                  : rowData.monthly_purchase_cost
              }`}
              subtitle="Monthly Purchase Cost"
              progress="0.75"
              increase="+14%"
              icon={
                <CurrencyRupeeIcon
                  sx={{ color: colors.pure[900], fontSize: "36px" }}
                />
              }
            />
          </Box>

          <Box
            height="120px"
            width={"100%"}
            // backgroundColor={colors.primary[400]}
            backgroundColor={colors.card2["bg"]}
            borderRadius="4px"
            boxShadow="0 2px 6px rgba(0, 0, 0, 0.16)"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title={`₹ ${
                rowData?.total_outstanding === undefined ||
                rowData?.total_outstanding === null
                  ? "0"
                  : rowData?.total_outstanding
              }`}
              subtitle="Total Outstanding amount"
              progress="0.75"
              increase="+14%"
              icon={
                <CreditCardIcon
                  sx={{ color: colors.pure[900], fontSize: "40px" }}
                />
              }
            />
          </Box>

          {/* <Box
            height="120px"
            width={isMobile?"49%":'29%'}
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title="25689 Rs"
              subtitle="Money Saved"
              progress="0.75"
              increase="+14%"
              icon={
                <CurrencyRupee
                  sx={{ color: colors.pure[900], fontSize: "36px" }}
                />
              }
            />
          </Box> */}
        </Box>

        {/* <Box display="flex" marginTop="5px" gap="6px">
          <Box
            height="120px"
            width="100%"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title="25689 Rs"
              subtitle="Money Saved"
              progress="0.75"
              increase="+14%"
              icon={
                <SavingsIcon
                  sx={{ color: colors.pure[900], fontSize: "36px" }}
                />
              }
            />
          </Box>

          <Box
            height="120px"
            width={"100%"}
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title="12,36 Kms"
              subtitle="Kilometers Saved"
              progress="0.75"
              increase="+14%"
              icon={
                <Route sx={{ color: colors.pure[900], fontSize: "36px" }} />
              }
            />
          </Box>

          <Box
            height="120px"
            width={"100%"}
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <StatBox
              title="12 Kgs"
              subtitle="Carbon Saved"
              progress="0.75"
              increase="+14%"
              icon={<Co2 sx={{ color: colors.pure[900], fontSize: "40px" }} />}
            />
          </Box>
        </Box> */}

        <Box
          height="180px"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          paddingInline="50px"
          alignItems="center"
          backgroundColor={colors.card2["bg"]}
          borderRadius="4px"
          boxShadow="0 2px 6px rgba(0, 0, 0, 0.16)"
          marginTop="10px"
          width="100%"
          // style={{
          //   backgroundImage: `url(${bgImg})`
          // }}
          sx={{
            "@media (max-width: 600px)": {
              flexDirection: "column",
              height: "auto",
              padding: "20px",
              gap: "20px",
            },
          }}
        >
          <Box
            display="flex"
            flexDirection={isMobile ? "column" : "row"}
            justifyContent="center"
            alignItems="center"
            gap="10px"
          >
            {/* <SavingsIcon sx={{fontSize:'60px',color:'gold'}}/> */}
            <LazyLoadImage
              height={150}
              width={150}
              src={require("../img/piggy.png")}
              // effect="blur" // Optional effect for a smoother transition when loading
              style={{
                // filter: colors.pngcol["filter"],
                marginBottom: "25px",
                fontSize: isMobile ? "1.5rem" : "inherit",
              }}
              sx={{
                "@media (max-width: 600px)": {
                  height: isMobile ? "80px" : "100px",
                  width: isMobile ? "80px" : "100px",
                },
              }}
            />
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="left"
              sx={{
                ml: isMobile ? 0 : 6,
                fontSize: isMobile ? "0.8rem" : "inherit",
                marginBottom: isMobile ? "10px" : "inherit",
              }}
            >
              <Typography
                fontWeight="600"
                sx={{ ml: isMobile ? 4 : 0 }}
                variant="h2"
              >
                You Have{" "}
                <span style={{ fontWeight: "600", fontSize: "35px" }}>
                  Saved
                </span>
              </Typography>
              <Typography
                fontWeight="600"
                color={colors.grey[700]}
                variant="h6"
                display="flex"
                alignItems="center"
                sx={{ fontSize: isMobile ? "11px" : "14px" }}
              >
                <Info />
                You will Save More than 1000 Rs per 1050 Ltrs with Romulus
              </Typography>
            </Box>
          </Box>

          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            gap="50px"
            marginRight="20px"
            sx={{
              "@media (max-width: 600px)": {
                flexDirection: "column",
                gap: "20px",
                alignItems: "flex-start",
                marginRight: 0,
              },
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              {/* <Sell /> */}
              <Typography
                fontWeight="600"
                variant="h3"
                fontSize={isMobile ? "1.5rem" : "2rem"}
              >
                {!isLoading && (
                  <CountUp
                    start={0}
                    end={
                      rowData?.monthly_saved_amt === undefined ||
                      rowData?.monthly_saved_amt === null
                        ? "0"
                        : rowData?.monthly_saved_amt
                    }
                    duration={2.75}
                    separator=" "
                    decimals={2}
                    decimal="."
                    prefix="₹ "
                    onEnd={() => console.log("Ended! 👏")}
                    onStart={() => console.log("Started! 💨")}
                  >
                    {isLoading && <CircularProgress />}
                  </CountUp>
                )}
              </Typography>
              <Typography fontWeight="600" variant="h5">
                This Month
              </Typography>
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              {/* <Sell /> */}
              <Typography
                fontWeight="600"
                variant="h3"
                fontSize={isMobile ? "1.5rem" : "2rem"}
              >
                {!isLoading && (
                  <CountUp
                    start={0}
                    end={
                      rowData?.total_saved_amt === undefined ||
                      rowData?.total_saved_amt === null
                        ? "0"
                        : rowData?.total_saved_amt
                    }
                    duration={2.75}
                    separator=" "
                    decimals={2}
                    decimal="."
                    prefix="₹ "
                    onEnd={() => console.log("Ended! 👏")}
                    onStart={() => console.log("Started! 💨")}
                  ></CountUp>
                )}
              </Typography>
              <Typography fontWeight="600" variant="h5">
                In Total
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AssetPage;
