import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Avatar, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { toast } from "react-toastify";
import { CustomToast } from "../customtoast/CustomToast";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
// import DataUsageIcon from '@mui/icons-material/DataUsage';
// import BuildIcon  from '@mui/icons-material/Build ';
import SelectableCheckbox from "./CheckBox";
import Carousel from "../SlickSlider";
import { AuthContext } from "../../AuthContext";
import api from "../../axios";
import { useState } from "react";
import { coreBaseUrl } from "../../constants";

const assetType = () => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <AddCircleOutlineIcon />
      <Typography variant="body2">Datum</Typography>
    </Box>
  );
};

export default function EditAsset({ type,info,updateData }) {
  const { token } = React.useContext(AuthContext);
  const company_id = token.company_id ? token.company_id : token.id;

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setFormData({
      company: company_id,
      typeOfAsset: info.assetType,
      fuelType: "",
      assetState: info.state,
      assetRegistrationNumber: info.reg_no,
      assetCapacity: info.capacity,
      assetName: info.assetName,
      assetLocation: info.location_id,
      assetPincode: info.pincode,
      staff_incharge: info.incharge_id,
    });
    setSelectedDate(null);
    setOpen(false);
  };



  // assetId,
  //               assetType,
  //               assetName,
  //               capacity,
  //               pincode,
  //               location_id,
  //               state,
  //               incharge,
  //               last_order,
  //               reg_no,
  //               fuelType,
  const [formData, setFormData] = React.useState({
    company: company_id,
    typeOfAsset: info.assetType,
    fuelType: "",
    assetState: info.state,
    assetRegistrationNumber: info.reg_no,
    assetCapacity: info.capacity,
    assetName: info.assetName,
    assetLocation: info.location_id,
    assetPincode: info.pincode,
    staff_incharge: info.incharge_id,
  });


  

  let assetIcon = null;

  if (info.assetType === "Datum") {
    assetIcon = require("../../assets/datum.png");
  } else if (info.assetType === "Heavy vehicle") {
    assetIcon = require("../../assets/truck.png");
  } else if (info.assetType === "Infra Machine") {
    assetIcon = require("../../assets/citytech-logo.png");
  } else if (info.assetType === "DG set") {
    assetIcon = require("../../assets/generator.png");
  } else if (info.assetType === "Industrial") {
    assetIcon = require("../../assets/factory.png");
  } else if (info.assetType === "Others") {
    assetIcon = require("../../assets/oil-pump.png");
  } else {
    assetIcon = require("../../assets/industry.png");
  }

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80vw",
    maxHeight: "80vh",
    bgcolor: colors.background[900],
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    overflow: "auto",
  };

  const [selectedDate, setSelectedDate] = React.useState(null);

  const [userData, setUserData] = React.useState([]);

  const handleChange = (event) => {
    console.log(formData);
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleAssetChange = (value) => {
    setFormData((prevData) => ({ ...prevData, ["typeOfAsset"]: value }));
    if (value === "Datum") {
      setFormData((prevData) => ({ ...prevData, ["assetCapacity"]: "2000" }));
    } else {
      setFormData((prevData) => ({ ...prevData, ["assetCapacity"]: "" }));
    }
    console.log("9090909090909090", formData);
  };

  const handleQty = (value) => {
    setFormData((prevData) => ({ ...prevData, ["assetCapacity"]: value }));
    console.log(formData);
  };

  const handleSubmit = () => {
    console.log(token.id);

    api
      .put(`assets/${info.assetId}`, formData)
      .then((response) => {
        console.log(response.data);
        updateData();
        handleClose();
      })
      .catch((error) => {
        console.log('44444444444444444',error);
        const errorMessages = error.response.data.message;
        const keys = Object.keys(errorMessages);
        const values = Object.values(errorMessages);

        toast.error(<CustomToast keys={keys} values={values} />);
      });
  };

  const getStaff = () => {
    const params = {
      staff_only: true,
    };
    api.get(`staff/${company_id}`, { params }).then((response) => {
      setUserData(response.data);
      console.log("5555555555555555", response.data);
    }).catch((error)=>{
      if (error.response.status === 403){
        window.location.reload()
      }
    });
  };

  const [locations, setLocations] = useState([]);

  const getLocations = async () => {
    await axios.get(`${coreBaseUrl}location/${company_id}`).then((response) => {
      console.log("cdcdccd", response.data);
      setLocations(response.data);
    }).catch((error)=>{
      if (error.response.status === 403){
        window.location.reload()
      }
    });
  };

  React.useEffect(() => {
    getLocations();
    getStaff();
  }, []);

  return (
    <div>
      {type==='add'?<Button
        sx={{
          backgroundColor: "rgba(62, 67, 150, 1.0)",
          color: "white",
          fontSize: "14px",
          fontWeight: "bold",
          padding: "10px 20px",
          borderRadius: 10,
          "&:hover": {
            backgroundColor: colors.blueAccent[800],
          },

          "@media (max-width: 600px)": {
            fontSize: "8px",
            padding: "8px 16px",
          },
        }}
        onClick={handleOpen}
      >
        <AddCircleOutlineIcon />
        &nbsp;Add Asset
      </Button>
    :
    <MenuItem onClick={handleOpen}>Edit</MenuItem>
    }
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography variant="h3" align="center">
            Edit Asset Details
          </Typography>
          <Box
            sx={{
              position: "absolute",
              top: 20,
              right: 20,
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Typography
              variant="body1"
              sx={{ marginLeft: 1, cursor: "pointer" }}
              onClick={handleClose}
            >
              Close
            </Typography>
            <CloseIcon sx={{ cursor: "pointer" }} onClick={handleClose} />
          </Box>

          <Box>
            <Grid container spacing={2}>
              <FormControl component="fieldset" fullWidth>
                <Typography variant="subtitle1" gutterBottom>
                  Asset Type
                </Typography>
                <Box display="flex" flexDirection="row" overflow="auto">
                  
                <SelectableCheckbox
                    name={formData.typeOfAsset}
                    disabled={true}
                    value={formData.typeOfAsset}
                    // onChange={() => handleAssetChange("Datum")}
                    icon={
                      <img
                        src={assetIcon}
                        style={{ filter: colors.pngcol["filter"] }}
                        width="25"
                        height="25"
                      />
                    }
                  />
                  
                  <Typography sx={{mt:4}} >You cannot change the type of asset</Typography>
                
              
                </Box>
              </FormControl>
              <Grid item xs={12} md={6} sx={{pt:0}}>
              
                {formData.typeOfAsset === "Datum" ||
                formData.typeOfAsset === "DG set" ||
                formData.typeOfAsset === "Infra machine" ||
                formData.typeOfAsset === "Heavy vehicle" ? (
                  ""
                ) : (
                  <FormControl fullWidth margin="normal">
                    <InputLabel id="asset-state-label">Asset State</InputLabel>
                    <Select
                      name="assetState"
                      value={formData.assetState}
                      labelId="asset-state-label"
                      onChange={handleChange}
                      variant="filled"
                    >
                      <MenuItem value="stationary">Stationary</MenuItem>
                      <MenuItem value="movable">Movable</MenuItem>
                    </Select>
                  </FormControl>
                )}

                {formData.typeOfAsset === "Heavy vehicle" ? (
                  <FormControl fullWidth margin="normal">
                    <TextField
                      name="assetRegistrationNumber"
                      value={formData.assetRegistrationNumber}
                      label="Registration Number"
                      variant="filled"
                      onChange={handleChange}
                    />
                  </FormControl>
                ) : (
                  ""
                )}

                <FormControl fullWidth margin="normal">
                  <TextField
                    name="assetName"
                    value={formData.assetName}
                    label="Preferred Name"
                    variant="filled"
                    onChange={handleChange}
                  />
                </FormControl>

                <FormControl fullWidth margin="normal">
                  <InputLabel id="asset-state-label">Location</InputLabel>
                  <Select
                    name="assetLocation"
                    value={formData.assetLocation}
                    onChange={handleChange}
                    variant="filled"
                  >
                    {locations.map((obj) => {
                      return (
                        <MenuItem value={obj.id}>
                          <Box
                            width="100%"
                            display="flex"
                            flexDirection="row"
                            gap="5px"
                            justifyContent="space-between"
                          >
                            <Box display="flex" flexDirection="row" gap="6px">
                              {/* <Avatar /> */}
                              <Box
                                display="flex"
                                flexDirection="column"
                                marginTop="-3px"
                              >
                                <Typography fontWeight="700">
                                  {obj.location}
                                </Typography>

                                {/* <Typography fontSize="13px">
                                  {obj.number}
                                </Typography> */}
                              </Box>
                            </Box>
                          </Box>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>

                <FormControl fullWidth margin="normal">
                  <TextField
                    name="assetPincode"
                    value={formData.assetPincode}
                    label="Pincode"
                    variant="filled"
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                {formData.typeOfAsset !== "Datum" ? (
                  <FormControl fullWidth margin="normal">
                    <TextField
                      name="assetCapacity"
                      value={formData.assetCapacity}
                      label="Capacity in Liters"
                      variant="filled"
                      onChange={handleChange}
                    />
                    <div
                      style={{
                        display: "flex",
                        marginTop: "8px",
                        gap: 5,
                        overflow: "auto",
                        paddingTop: "5px",
                        "&::-webkit-scrollbar": {
                          width: "10px",
                        },
                      }}
                    >
                      <Button
                        variant="contained"
                        sx={{
                          bgcolor: colors.grey[900],
                          color: colors.pure[900],
                          "&:hover": { bgcolor: colors.grey[800] },
                        }}
                        onClick={() => {
                          handleQty("50");
                        }}
                      >
                        50
                      </Button>
                      <Button
                        variant="contained"
                        sx={{
                          bgcolor: colors.grey[900],
                          color: colors.pure[900],
                          "&:hover": { bgcolor: colors.grey[800] },
                        }}
                        onClick={() => {
                          handleQty("100");
                        }}
                      >
                        100
                      </Button>
                      <Button
                        variant="contained"
                        sx={{
                          bgcolor: colors.grey[900],
                          color: colors.pure[900],
                          "&:hover": { bgcolor: colors.grey[800] },
                        }}
                        onClick={() => {
                          handleQty("200");
                        }}
                      >
                        200
                      </Button>
                      <Button
                        variant="contained"
                        sx={{
                          bgcolor: colors.grey[900],
                          color: colors.pure[900],
                          "&:hover": { bgcolor: colors.grey[800] },
                        }}
                        onClick={() => {
                          handleQty("300");
                        }}
                      >
                        300
                      </Button>
                      <Button
                        variant="contained"
                        sx={{
                          bgcolor: colors.grey[900],
                          color: colors.pure[900],
                          "&:hover": { bgcolor: colors.grey[800] },
                        }}
                        onClick={() => {
                          handleQty("500");
                        }}
                      >
                        500
                      </Button>

                      <Button
                        variant="contained"
                        sx={{
                          bgcolor: colors.grey[900],
                          color: colors.pure[900],
                          "&:hover": { bgcolor: colors.grey[800] },
                        }}
                        onClick={() => {
                          handleQty("1000");
                        }}
                      >
                        1000
                      </Button>

                      <Button
                        variant="contained"
                        sx={{
                          bgcolor: colors.grey[900],
                          color: colors.pure[900],
                          "&:hover": { bgcolor: colors.grey[800] },
                        }}
                        onClick={() => {
                          handleQty("");
                        }}
                      >
                        Custom
                      </Button>
                    </div>
                  </FormControl>
                ) : (
                  ""
                )}

                <FormControl fullWidth margin="normal">
                  <InputLabel id="asset-state-label">Staff Incharge</InputLabel>
                  <Select
                    name="staff_incharge"
                    value={formData.staff_incharge}
                    onChange={handleChange}
                    variant="filled"
                  >
                    {userData.map((obj) => {
                      return (
                        <MenuItem value={obj.id}>
                          <Box
                            width="100%"
                            display="flex"
                            flexDirection="row"
                            gap="5px"
                            justifyContent="space-between"
                          >
                            <Box display="flex" flexDirection="row" gap="6px">
                              <Avatar />
                              <Box
                                display="flex"
                                flexDirection="column"
                                marginTop="-3px"
                              >
                                <Typography fontWeight="700">
                                  {obj.username}
                                </Typography>

                                <Typography fontSize="13px">
                                  {obj.number}
                                </Typography>
                              </Box>
                            </Box>

                            {/* <Box marginTop="-3px" width='25%' paddingLeft='5px'>
                          <Typography>
                            {obj.assetRegistrationNumber !== ""
                              ? `Reg No : ${obj.assetRegistrationNumber}`
                              : `Capacity : ${obj.assetCapacity} Ltrs`}
                          </Typography>

                          <Typography variant='p' fontSize="14px" >
                          
                            Location : {obj.assetLocation.length > 15 ? `${obj.assetLocation.slice(0, 15)}...` : obj.assetLocation}
                          </Typography>
                        </Box> */}
                          </Box>
                        </MenuItem>
                      );
                    })}

                    {/* <MenuItem value="movable">Movable</MenuItem> */}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>

          <Button
            onClick={handleSubmit}
            variant="contained"
            sx={{
              backgroundColor: "#3e4396",
              alignItems: "center",
              width: "30%",
              ml: "35%",
              mt: "20px",
            }}
          >
            Submit
          </Button>
        </Box>
      </Modal>
    </div>
  );
}
