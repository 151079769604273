import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const LoadingIndicator = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100%",
      }}
    >
      <CircularProgress />
      Loading..
    </div>
  );
};

export default LoadingIndicator;