import { Box, CircularProgress, IconButton, List, ListItem, ListItemText, Popover, Typography, useTheme } from "@mui/material";
import { useContext, useState } from "react";
import { ColorModeContext, tokens } from "../../theme";
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SearchIcon from "@mui/icons-material/Search";
import rmlogo from '../../assets/rmlogo.png'
import api from "../../axios";
import { coreBaseUrl } from "../../constants";
import axios from "axios";
// import LoadingIndicator from "../../components/LoadingIndicator";
import ProgressCircle from "../../components/ProgressCircle";
import DarkModeBt from './DarkModeSwitch';
import { AuthContext } from "../../AuthContext";
import { useNavigate } from "react-router-dom";

// import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';

const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const isMobile = window.innerWidth <= 600

  
  const [anchorEl, setAnchorEl] = useState('')

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'profile-popover' : undefined;

  const [isLoading, setIsLoading] = useState(false)
  const {checkAuth} = useContext(AuthContext)
  const navigate = useNavigate()

  const coreBaseUrl = process.env.REACT_APP_ADMIN_BASEURL;
  const handleLogout = async () => {
    await axios.get(`${coreBaseUrl}logout`, {withCredentials:true}).then((response)=>{
      console.log('logedout');
      navigate('/login')
      // setIsLoading(true)
      // checkAuth()
      window.location.reload()
      
      
    }).catch((error)=>{
      console.log('errrrr',error);
    })
    // window.location.reload()
    
    // setTimeout(() => {
    //   window.location.reload();
    //   setIsLoading(false)
    // }, 2000);
    
  }

  // if (isLoading === true ){
  //   return <LoadingIndicator/>
  // }

  return (
    <Box display="flex" justifyContent="space-between" p={2}>
      {/* SEARCH BAR */}

      <Box
        display="flex"
        // backgroundColor={colors.primary[400]}
        borderRadius="3px"
        alignItems="center"
        marginLeft={isMobile ? '40px' : '0'}
      >
        <img src={rmlogo} width={isMobile ? 30 : 48} height={isMobile ? 26 : 41} alt='logo'></img>
        <Typography sx={{ textAlign: "center" }}>
          <span style={{ display: "block", lineHeight: "1",fontSize:isMobile?'1rem':'1.5rem',color:'chocolate' }}>ROMULUS</span>
          <span style={{ display: "block", lineHeight: "1",fontSize:isMobile?'1rem':'1.5rem',color:'chocolate' }}>OIL &amp; GAS</span>
        </Typography>
      </Box>


      {/* ICONS */}
      <Box display="flex">
        <div onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            // <DarkModeOutlinedIcon />
            <DarkModeBt size='small' sx={{ m: 1 }} />
          ) : (
            // <LightModeOutlinedIcon />
            <DarkModeBt size='small' sx={{ m: 1 }} />
          )}
        </div>
        {/* <IconButton>
          <NotificationsOutlinedIcon />
        </IconButton> */}
        
        {/* <IconButton>
          <SettingsOutlinedIcon />
        </IconButton> */}

        <IconButton onClick={handleClick}>
          <PersonOutlinedIcon />
        </IconButton>
        <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <List>
          <ListItem button>
            <ListItemText primary="Profile" />
          </ListItem>
          
          {isLoading === false ?
            <ListItem button onClick={handleLogout}>
            <ListItemText primary="Logout" />
          </ListItem>
          :
          <ListItem button onClick={handleLogout}>
            <ListItemText sx={{display:'flex',flexDirection:'row', fontSize:'1rem'}} primary="Loging Out" secondary={<CircularProgress sx={{maxWidth:'10px',maxHeight:'10px'}}/>}/>
          </ListItem>
          // <CircularProgress sx={{maxWidth:'10px',maxHeight:'10px'}}/>
          }

        </List>
      </Popover>

      </Box>
    </Box>
  );
};

export default Topbar;
