// DownloadButton.js

import React from "react";
import { Button } from "@mui/material";
// import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";
import { DownloadForOfflineOutlined } from "@mui/icons-material";





const DownloadInvoiceButton = ({ fileUrl }) => {

    const handleViewPdf = () => {
      const newTab = window.open(fileUrl, "_blank");
      if (newTab) {
        newTab.focus();
      } else {
        // If the new tab was blocked by the browser, show an error message
        console.error("Error opening PDF: Pop-up blocked by the browser.");
      }
    };

//   const handleDownloadPdf = async () => {
//     try {
//       const objectKey = getObjectKeyFromUrl(fileUrl);

//       const response = await s3Client.send(
//         new GetObjectCommand({
//           Bucket: BUCKET_NAME,
//           Key: objectKey,
//         })
//       );

//       console.log('====================================');
//       console.log(response);
//       console.log('====================================');

//       const fileData = new Blob([response.Body]);
//       const fileBlobUrl = URL.createObjectURL(fileData);

//       const downloadLink = document.createElement("a");
//       downloadLink.href = fileBlobUrl;
//       downloadLink.download = "your-pdf-file-name.pdf";
//       document.body.appendChild(downloadLink);

//       downloadLink.click();

//       document.body.removeChild(downloadLink);
//     } catch (error) {
//       console.error("Error downloading PDF:", error);
//     }
//   };

  return (
    <Button variant="contained" sx={{bgcolor:'green'}} onClick={handleViewPdf}>
      <DownloadForOfflineOutlined/> Download
    </Button>
  );
};

export default DownloadInvoiceButton;
