import React, { useContext, useRef } from "react";
import Slider from "react-slick";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTheme } from "@mui/material";
import { tokens } from "../theme";
import MainContext from "../contexts/MainContext";
import { useMediaQuery,Box } from "@mui/material"


const Carousel = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const sliderRef = useRef(null);
  const {assetFilter,setAssetFilter} = useContext(MainContext)
  
  const filter = [
    {
      name: "All",
      icon: require('../assets/industry.png'),
    },
    {
      name: "Datum",
      icon: require('../assets/datum.png'),
    },
    {
      name: "Heavy vehicle",
      icon: require('../assets/truck.png'),
    },
    {
      name: "Infra Machine",
      icon: require('../assets/citytech-logo.png'),
    },
    {
      name: "DG set",
      icon: require('../assets/generator.png'),
    },
    {
      name: "Industrial",
      icon: require('../assets/factory.png'),
    },
    {
        name: "Others",
        icon: require('../assets/oil-pump.png'),
      },
  ];


  const handleSelect = (name) =>{
    console.log(assetFilter);
    if (assetFilter===name){
        setAssetFilter('All')
    }
    else{
        setAssetFilter(name)
    }
  }

  const handleWheel = (event) => {
    const delta = Math.sign(event.deltaY);
    if (delta > 0) {
      sliderRef.current.slickNext();
    } else {
      sliderRef.current.slickPrev();
    }
  };
  const isMobile = useMediaQuery('(max-width : 600px)')
  const settings = {
    //   dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: isMobile?4:8,
    slidesToScroll: 4,
    prevArrow: <ArrowBackIosNewIcon sx={{ color: colors.grey[900] }} />,
    nextArrow: <ArrowForwardIosIcon sx={{ color: colors.grey[900] }}  />,
  };



  return (
    <div style={{ width: "70vw",marginLeft:isMobile?'13%':'6%' }}>
      <div onWheel={handleWheel}>
        <Slider {...settings} ref={sliderRef}>
          {/* Render your carousel slides */}
          {filter.map((obj) => {
            return (
              <div>
                <Box
                onClick={()=>{handleSelect(obj.name)}}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: assetFilter === obj.name ?colors.card['selection']:colors.card['bg'],
                    marginInline: "4px",
                    flexDirection: "column",
                    padding: "8px",
                    // maxWidth:'10px',
                    minHeight:'6rem',
                    transition: "background-color 0.3s ease",
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: colors.card['hover'],
                    },
                  }}
                >
                  {/* {obj.icon} */}
                  <img style={{filter:colors.pngcol['filter']}} height='40px' width='40px' src={obj.icon}/>
                  <span
                  style={{ color: colors.grey[100],fontWeight:'700' }}>{obj.name}</span>
                </Box>
              </div>
            );
          })}

          {/* Add more slides as needed */}
        </Slider>
      </div>
    </div>
  );
};

export default Carousel;
