import React from 'react'
import { useContext } from 'react'
import { useEffect } from 'react'
import { AuthContext } from './AuthContext'
import { useNavigate } from 'react-router-dom'

function ServerDown() {

  const {isAuth} = useContext(AuthContext)
  const navigate = useNavigate()
  useEffect(() => {
   if (isAuth){
    navigate('/')
   }
  }, [])
  
  return (
    <div>Server is down now. Please Corporate, We are trying to fix it ASAP.</div>
  )
}

export default ServerDown