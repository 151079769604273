import axios from 'axios';
import { Baseurl } from './constants';


const api = axios.create({
  baseURL: process.env.REACT_APP_USER_BASEURL,
  withCredentials: true,
});



export default api ;